<template>
    <div>Esporta Categorie</div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Comunicazioni", route: "/circular" },
            { title: "Categorie", route: "/circular/category/index" },
            { title: "Esporta Categorie" }
        ]);
    }
};
</script>
